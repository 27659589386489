<template>
  <section id="workflow-users-list">
    <div class="inner-navbar">
      <el-row type="flex" align="middle">
        <el-col :span="12">
          <div class="icon-block d-flex align-center">
            <div class="icon">
              <el-button
                icon="el-icon-arrow-left"
                @click="backToFormbuilder"
                circle
              ></el-button>
            </div>

            <div class="icon-text pl-1">
              <h3 class="table-head-text">{{ getFlowName() }}</h3>
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <el-input
            placeholder="Search"
            v-model="searchQuery"
            size="small"
            clearable
            class="Searching flaot-right mr-1"
            prefix-icon="el-icon-search"
          >
          </el-input>
        </el-col>
        <div
          class="right-block float-right mr-1"
          v-if="IframePermissions('Export', 'FormBuildersTemplateDataList')"
        >
          <templates-data-export-widget
            :formbuilderDetails="formbuilderDetails"
            :templatesFields="templateFields"
            :selectedFields="sectedFieldKeys"
          ></templates-data-export-widget>
        </div>
        <div class="right-block float-right">
          <el-tooltip content="Create Iframe link" placement="top">
            <el-link
              v-if="getIframeUrlInfo == null"
              icon="el-icon-link"
              style="font-size: 24px; float: right; margin-top: 5px"
              :underline="false"
              class="mt-"
              @click="openViewConfigPage"
            ></el-link>
          </el-tooltip>
        </div>
      </el-row>
    </div>

    <div class="inner-navbar-content top">
      <div class="vue-data-table-default table-scroll">
          <el-table
            :data="filteredFBDetailedData"
            :total="total"
            :resizable="true"
            border
            :current-page.sync="currentPage"
            :page-size="pageSize"
            :pagination-props="paginationProps"
            @query-change="loadData"
            v-loading="loading"
            element-loading-text="Fetching data, please wait..."
            element-loading-spinner="el-icon-loading"
            @sort-change="sortChange"
            style="overflow-wrap: normal"
            class="sidemenu"
          >
            <el-table-column fixed="left" label="User" min-width="220">
              <template slot-scope="scope">
                <div class="d-flex" v-if="!isAnonymousForm()">
                  <div>{{ getUserEmail(scope.row) }}</div>
                </div>
                <div v-else>
                  <div>{{ scope.row.ip_address }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              class="tableView"
              v-for="(field, index) of allFields
                .filter(
                  (e) =>
                    e.input_type !== 'DATA_TABLE' &&
                    e.input_type !== 'ENTITY_TABLE'
                )
                .filter((e) => contentFields.indexOf(e.input_type) === -1)"
              :key="index"
              :label="field.label"
              sortable="custom"
              :prop="field.data_key"
              min-width="250"
            >
              <template slot-scope="scope">
                <div
                  v-if="
                    field.input_type == 'FILE' ||
                    (field.input_type == 'ENTITY_VARIABLE' &&
                      field.inputType == 'FILE')
                  "
                >
                  <div
                    v-if="
                      getFieldData(scope.row, field) &&
                      getFieldData(scope.row, field).length &&
                      getFieldData(scope.row, field).length <= 1
                    "
                  >
                    <el-button
                      type="text"
                      style="margin-top: -14px"
                      @click="downloadFile(getFieldData(scope.row, field)[0])"
                      class="mb-1"
                    >
                      Download
                      <i class="el-icon-download"></i>
                    </el-button>
                  </div>
                  <div
                    class="d-flex"
                    v-else-if="
                      getFieldData(scope.row, field) &&
                      getFieldData(scope.row, field).length &&
                      getFieldData(scope.row, field).length >= 1
                    "
                  >
                    <p
                      v-for="(file, index) of getFieldData(scope.row, field)"
                      :key="index"
                    >
                      <el-button
                        type="text"
                        style="margin-top: 0px"
                        v-if="file && file.name && index < limit"
                        @click="downloadFile(file)"
                      >
                        {{ file.name | truncate(10, "...")
                        }}<i class="el-icon-download"></i>
                      </el-button>
                      <br />
                    </p>
                    <el-tag
                      style="cursor: pointer; width: 40px"
                      class="ml-1"
                      :value="getFieldData(scope.row, field).length"
                      v-if="getFieldData(scope.row, field).length > 1"
                      @click="downloadDocumentsPopup()"
                    >
                      +{{ getFieldData(scope.row, field).length - 1 }}
                    </el-tag>
                  </div>
                  <!-- </p> -->
                </div>

                <div
                  class="d-flex"
                  v-else-if="
                    field &&
                    (field.input_type === 'IMAGE' ||
                      field.input_type === 'GIF') &&
                    getFieldData(scope.row, field)
                  "
                >
                  <img
                    :src="getFieldData(scope.row, field)"
                    alt="IMG"
                    width="100"
                    height="60"
                  />
                </div>
                <div
                  v-else-if="
                    field &&
                    (field.input_type === 'DATE' || field.inputType === 'DATE')
                  "
                >
                  <p style="font-size: 13px">
                    {{ getFieldData(scope.row, field) | globalDateFormat }}
                  </p>
                </div>
                <div
                  v-else-if="
                    (field.input_type == 'DATE_TIME' ||
                      (field.inputType == 'DATE_TIME' &&
                        field.input_type === 'ENTITY_VARIABLE')) &&
                    getFieldData(scope.row, field)
                  "
                >
                  {{
                    getFieldData(scope.row, field)
                      | moment("MM-DD-YYYY hh:mm:ss A")
                  }}
                </div>
                <div
                  v-else-if="
                    field &&
                    (field.input_type == 'DATE_TIME_RANGE' ||
                      (field.inputType == 'DATE_TIME_RANGE' &&
                        field.input_type === 'ENTITY_VARIABLE')) &&
                    getFieldData(scope.row, field)
                  "
                >
                  <p style="font-size: 13px">
                    {{
                      getFieldData(scope.row, field)
                        | moment("MM-DD-YYYY hh:mm:ss A")
                    }}
                  </p>
                </div>
                <div
                  v-else-if="
                    field &&
                    (field.input_type == 'DATE_RANGE' ||
                      (field.inputType == 'DATE_RANGE' &&
                        field.input_type === 'ENTITY_VARIABLE')) &&
                    getFieldData(scope.row, field)
                  "
                >
                  <p style="font-size: 13px">
                    {{ getFieldData(scope.row, field) | moment("MM-DD-YYYY") }}
                  </p>
                </div>
                <div v-else-if="field && field.input_type == 'TIME'">
                  <p style="font-size: 13px">
                    {{ getFieldData(scope.row, field) | moment("hh:mm") }}
                  </p>
                </div>
                <div
                  v-else-if="
                    field.input_type == 'SIGNATURE' ||
                    (field.input_type == 'ENTITY_VARIABLE' &&
                      field.inputType == 'SIGNATURE')
                  "
                >
                  <img
                    v-if="getFieldData(scope.row, field)"
                    :src="getFieldData(scope.row, field)"
                    alt="IMG"
                    width="100"
                    height="60"
                  />
                  <p v-else>-</p>
                </div>
                <div v-else-if="field.input_type == 'CURRENCY'">
                  {{
                    Intl.NumberFormat(getCurrencyFormet(field).code, {
                      style: "currency",
                      currency: getCurrencyFormet(field).currency,
                    }).format(getFieldData(scope.row, field))
                  }}
                </div>
                <div v-else-if="field.input_type == 'FORMULA'">
                  <p v-if="field.result_type == 'CURRENCY'">
                    {{
                      Intl.NumberFormat(getCurrencyFormet(field).code, {
                        style: "currency",
                        currency: getCurrencyFormet(field).currency,
                      }).format(getFieldData(scope.row, field))
                    }}
                  </p>
                  <p v-else>{{ getFieldData(scope.row, field) }}</p>
                </div>
                <div v-else-if="field && field.input_type == 'NUMBER'">
                  <p style="font-size: 13px">
                    {{ getFieldData(scope.row, field) }}
                  </p>
                </div>
                <div v-else-if="field.input_type == 'CAPTCHA'">
                  <p v-if="getFieldData(scope.row, field)">
                    {{ getFieldData(scope.row, field) }}
                  </p>
                  <p v-else>Not verified</p>
                </div>
                <span
                  v-else-if="field.input_type == 'RICH_TEXT'"
                  v-html="getFieldData(scope.row, field)"
                ></span>
                <span v-else-if="field.input_type === 'HYPER_LINK'">
                  <el-link
                    @click="checkNavigationAction(scope.row, field)"
                    style="color: rgb(69 188 237)"
                    >{{ getFieldData(scope.row, field) }}</el-link
                  >
                </span>
                <!-- <span
                  v-else-if="field.input_type == 'MULTI_LINE_TEXT'"
                  v-html="getFieldData(scope.row, field)"
                >
                </span> -->
                <div v-else-if="field && field.input_type == 'MULTI_LINE_TEXT'">
                  <span v-if="getFieldData(scope.row, field)">
                    <span v-if="getFieldData(scope.row, field).length > 100">
                      {{ truncateText(getFieldData(scope.row, field)) }}
                      <a
                        href="#"
                        @click.prevent="
                          openMultiLineData(
                            getFieldData(scope.row, field),
                            field.label,
                            field
                          )
                        "
                        style="color: #409eff"
                        >Read more</a
                      >
                    </span>
                    <span v-else>
                      {{ getFieldData(scope.row, field) }}
                    </span>
                  </span>
                </div>
                <div
                  v-else-if="field && field.input_type == 'AGGREGATE_FUNCTION'"
                >
                  {{ getFieldData(scope.row, field) }}
                </div>
                <div
                  v-else-if="
                    getFieldData(scope.row, field) &&
                    field.input_type != 'DATA_TABLE'
                  "
                >
                  {{ getFieldData(scope.row, field) }}
                </div>
                <div
                  v-else-if="
                    getFieldData(scope.row, field) &&
                    field.inputType == 'PHONE_COUNTRY_CODE'
                  "
                >
                  {{ getFieldData(scope.row, field) }}
                </div>
                <div v-else-if="getFieldData(scope.row, field) && field.input_type == 'PASSWORD'">
                  {{ getFieldData(scope.row, field) }}
                </div>
                <div v-else>-</div>
              </template>
            </el-table-column>
          </el-table>
      </div>
    </div>
    <el-pagination
      v-if="!loading"
      :class="'custom-pagination'"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-sizes="pageSizes"
      :page-size="pageSize"
      :pager-count="5"
      layout="prev, pager, next, jumper, sizes, total"
      :total="total"
      small
    ></el-pagination>
    <!-- <el-dialog
      :visible.sync="downloadDocuments"
      title="Download Documents"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="sidemenu">
        <el-table
          border
          :data="data"
          :header-row-class-name="'my-header-row'"
          class="tableSty"
          :show-header="true"
        >
          <el-table-column>
            <template slot-scope="scope"> -->
    <!-- {{ scope.row }} -->
    <!-- </template> -->
    <!-- <template
              slot-scope="scope"
              v-if="
                field.input_type == 'FILE' &&
                field.validations.multiple &&
                getFieldData(scope.row, field) &&
                getFieldData(scope.row, field).length &&
                getFieldData(scope.row, field).length >= 1
              "
            >
              <p
                style="font-size: 14px"
                slot="reference"
                v-for="(file, index) of getFieldData(scope.row, field)"
                :key="index"
              >
              <a @click="downloadFile(file)">
                {{ file.name | truncate(20, "...") }} Download <i class="el-icon-download"></i>
              </a>
                
              </p>
            </template> -->
    <!-- </el-table-column>
        </el-table>
      </div>

      <span slot="footer">
        <el-button @click="downloadDocuments = false"> Close </el-button>
      </span>
    </el-dialog> -->
    <dialog-component
      :modal="false"
      :visible="downloadDocuments"
      :title="'Download Documents'"
      :containerWidth="'700px'"
      @before-close="closeDownloadDocuments"
    >
      <!-- <el-row>
        <el-input
          class="search mb-1"
          placeholder="Search"
          v-model="searchDocument"
          clearable
          @input="searchDocumentName"
          prefix-icon="el-icon-search"
        ></el-input>
      </el-row> -->
      <div class="vue-data-table-default">
        <el-table
          border
          :data="documentsData"
          class="tableSty sidemenu"
          :show-header="true"
        >
          <el-table-column class="labelSty" label="Document Name">
            <template slot-scope="scope">
              <p style="font-size: 14px" slot="reference">
                {{ scope.row.name }}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="Action" width="150">
            <template slot-scope="scope">
              <ul class="action-buttons">
                <li>
                  <button class="btn btn-light btn-sm m-lr-1" type="button">
                    <span
                      class="text-muted"
                      @click="onPreview(scope.row)"
                      title="preview"
                    >
                      <i class="fa fa-eye"></i>
                    </span>
                  </button>
                </li>
                <li>
                  <button class="btn btn-light btn-sm m-lr-1" type="button">
                    <span
                      class="text-muted"
                      @click="onDownloadFile(scope.row)"
                      target="_blank"
                      ref="downloadLink"
                    >
                      <i class="el-icon-download"></i>
                    </span>
                  </button>
                </li>
              </ul>

              <!-- <el-button
                @click="onDownloadFile(scope.row)"
                target="_blank"
                ref="downloadLink"
                style="width: 120px; font-size: 12px"
              >
                Download
                <i class="el-icon-download"></i>
              </el-button> -->
            </template>
          </el-table-column>
        </el-table>
      </div>

      <span slot="footer">
        <el-button @click="downloadDocuments = false"> Close </el-button>
      </span>
    </dialog-component>
    <dialog-component
      :title="multiLineTextLabel"
      :visible="openMultiLineTextDialog"
      @before-close="closeMultiLneData"
      :isShowFooter="false"
      :containerMaxWidth="'50vw'"
    >
      <div style="padding: 20px" class="desription-text" v-if="isRichText">
        <!-- <ckeditor :config="editorConfig" v-model="multiLineTextData">
      </ckeditor> -->
        <div v-html="multiLineTextData"></div>
      </div>
      <div class="desription-text" v-else>
        {{ multiLineTextData | truncateHTML() }}
      </div>
    </dialog-component>
    <el-drawer
      title="Privacy Link"
      :visible.sync="copyModal"
      :direction="'rtl'"
      :with-header="true"
      :size="getIsMobile ? '50%' : '20%'"
      ><IframeLink
        :routeKey="url_key"
        type="FormBuildersTemplateDataList"
      ></IframeLink>
    </el-drawer>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import TemplateBuilderhelper from "@/mixins/templateBuilderHelper.js";
import fieldsDataHelper from "@/mixins/fieldsDataHelper.js";
import NavigationHelper from "@/mixins/navigationHelper";

export default {
  mixins: [TemplateBuilderhelper, fieldsDataHelper, NavigationHelper],
  components: {
    TemplatesDataExportWidget: () =>
      import("../widgets/templatesDataExportWidget"),
    IframeLink: () => import("../../views/iframeLink.vue"),
  },
  computed: {
    ...mapGetters("formBuilders", [
      "getFormBuilderAddStatus",
      "getFormBuilder",
      "getFormBuilderRemindersSettings",
      "getFormBuilderUpdateStatus",
      "getFormBuilderPublicURLDetails",
    ]),
    ...mapGetters("formbuilderData", [
      "getFormbuilderUsersData",
      "getFormbuilderUniqueUsersData",
      "getUsersFormbuilderTemplatesData",
    ]),
    ...mapGetters("templatesData", ["getDownloadUrl", "getDownloadError"]),
    ...mapGetters("auth", ["getDefaultDateFormat"]),
    ...mapGetters("iFrame", ["getIframeUrlInfo"]),
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
    getUserEmail() {
      return (formbuilderData) => {
        if (formbuilderData.contact) {
          return formbuilderData.contact && formbuilderData.contact.email
            ? formbuilderData.contact.email
            : "";
        }
        return "";
      };
    },
    filteredFBDetailedData() {
      if (!this.searchQuery) {
        return this.data;
      } else {
        const searchTerm = this.searchQuery.toLowerCase();
        return this.data.filter((row) => {
          return Object.values(row).some((value) => {
            if (typeof value === "number") {
              return String(value).toLowerCase().includes(searchTerm);
            } else if (typeof value === "string") {
              return value.toLowerCase().includes(searchTerm);
            } else if (typeof value === "object" && value !== null) {
              return JSON.stringify(value).toLowerCase().includes(searchTerm);
            }
            return false;
          });
        });
      }
    },
    paginationProps() {
      return {
        pageSizes: this.pageSizes || [10, 20, 50, 100],
      };
    },
  },
  data() {
    return {
      searchQuery: "",
      multipleFileNames: [],
      pageSize: 0,
      sortBy: "_id",
      sortOrder: -1,
      data: [],
      total: 0,
      currentPage: 1,
      formbuilderCode: "",
      formbuilderDetails: "",
      templateIds: [],
      templateFields: [],
      allFields: [],
      sectedFieldKeys: [],
      loading: false,
      downloadDocuments: false,
      limit: 1,
      documentsData: [],
      contentFields: [
        "IMAGE",
        "HEADING",
        "PARAGRAPH",
        "VIDEO",
        "SINGLE_LINE_CONTENT",
        "HORIZONTAL_LINE",
        "DIVISION",
        "HTML_CONTENT",
        "ACTION_BUTTON",
        "HTML_CONTENT",
      ],
      multiLineTextData: "",
      multiLineTextLabel: "",
      openMultiLineTextDialog: false,
      isRichText: false,
      copyModal: false,
      url_key: "",
      pageSizes: [10, 20, 50, 100],
    };
  },
  async mounted() {
    let limit = (this.pageSizes && this.pageSizes[0]) || 10;
    this.pageSize = limit;
    await this.getNecessaryInfo();
  },
  methods: {
    checkNavigationAction(rowData, stepData) {
      if (
        this.isValidURL(this.getFieldData(rowData, stepData)) &&
        this.isValidHttpUrl(
          this.getValidUrl(this.getFieldData(rowData, stepData))
        )
      ) {
        let url = this.getValidUrl(this.getFieldData(rowData, stepData));
        window.open(url, "_blank");
      } else {
        this.$message.info("Invalid URL");
      }
    },
    isValidURL(url) {
      let re =
        /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/gi;
      if (re.test(url)) {
        return true;
      } else {
        return false;
      }
    },
    getValidUrl(url) {
      if (!url.includes("http://") && !url.includes("https://"))
        return "https://" + url;
      return url;
    },
    isValidHttpUrl(string) {
      let url;
      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }
      return url.protocol === "http:" || url.protocol === "https:";
    },
    openMultiLineData(htmlContent, label, field) {
      console.log("openMultiLineDataopenMultiLineData");
      this.openMultiLineTextDialog = true;
      this.multiLineTextData = htmlContent;
      this.multiLineTextLabel = label;
      if ("currency_code" in field && "data_type" in field.currency_code) {
        this.isRichText = false;
      } else {
        this.isRichText = true;
      }
    },
    closeMultiLneData() {
      this.openMultiLineTextDialog = false;
      this.isRichText = null;
    },
    isAnonymousForm() {
      return this.formbuilderDetails?.form_builders_owner === "ANONYMOUS";
    },
    async downloadFile(data) {
      let fileName = data.name;
      fileName = await fileName.replace(/\//g, "-");
      fileName = await fileName.replace(/ /g, "_");
      fileName = await fileName.replace(/[()]/g, "");
      if (data && !data.path) {
        data.path = `template-data-documents/${fileName}`;
      }
      await this.$store.dispatch("templatesData/downloadTemplateDocument", {
        path: data.path,
      });
      await fetch(this.getDownloadUrl, {
        method: "GET",
        responseType: "blob",
      })
        .then((res) => res.blob())
        .then((response) => {
          var fileURL = window.URL.createObjectURL(response);
          const link = document.createElement("a");
          link.href = fileURL;
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      if (this.getDownloadUrl) {
        if (!data?.path?.includes('.csv') || !data?.name?.includes('.csv')) {
          window.open(this.getDownloadUrl, "_blank");
        }
      } else {
        this.$notify.error({
          title: "Error",
          message: this.getDownloadError || "Error while downloading",
        });
      }
    },
    getData() {
      let data = this.allFields.filter((el) => {
        if (el && el.inputType == "FILE") {
          return el;
        }
      });
      return data;
    },
    getCurrencyFormet(field) {
      if (
        field &&
        field.validations &&
        field.validations.currency &&
        field.validations.locale
      ) {
        return {
          currency: field.validations.currency,
          code: field.validations.locale,
        };
      }
      return {
        currency: "USD",
        code: "en-US",
      };
    },
    getFieldData(formbuilderData, field) {
      let templateData;
      if (
        field.entity_type_id &&
        formbuilderData.entityData &&
        formbuilderData.entityData[field.entity_type_id] &&
        formbuilderData.entityData[field.entity_type_id][field.template_id]
      ) {
        templateData =
          formbuilderData.entityData[field.entity_type_id][field.template_id];
      } else if (
        formbuilderData.templateData &&
        formbuilderData.templateData &&
        formbuilderData.templateData[field.template_id]
      ) {
        templateData = formbuilderData.templateData[field.template_id];
      }
      if (templateData) {
        let value = templateData[field.key];
        let otherValue = templateData[field.key + "/otheroption"]
          ? templateData[field.key + "/otheroption"]
          : null;
        // this.multipleFileNames = value.files.flatMap((el) => {
        //   return el.name;
        // });

        if (value || value == "0") {
          if (field.input_type === "NUMBER") {
            if (typeof value === "number") {
          return value + (field.properties?.number_type === "PERCENTAGE" ? "%" : "");
        } else if (!isNaN(value)) {
          return parseInt(value) + (field.properties?.number_type === "PERCENTAGE" ? "%" : "");
        }
      }
          else if (field.input_type == "DATE") {
            // let date = new Date(value).toLocaleDateString('en-US', {timeZone: 'america/new_york'});
            return moment(new Date(value)).format(
              this.getDefaultDateFormat &&
                this.getDefaultDateFormat.includes("Do") == false
                ? this.getDefaultDateFormat
                : "MM-DD-yyyy"
            );
          } else if (field.input_type == "NUMBER") {
            return value;
          } else if (field.inputType == "PHONE_COUNTRY_CODE") {
            if (
              templateData[field.key] &&
              templateData[field.key + "_code"]?.countryCallingCode &&
              templateData[field.key + "_code"]?.countryCode
            ) {
              value =
                "+" +
                templateData[field.key + "_code"]?.countryCallingCode +
                "(" +
                templateData[field.key + "_code"]?.countryCode +
                ") " +
                value;
              return value;
            }
            return value;
          } else if (field.input_type == "CAPTCHA") {
            return value ? "Verified" : "Not Verified";
          } else if (field.input_type == 'PASSWORD') {
            let pswdLength = templateData[field.key].length;
            return '*'.repeat(pswdLength);
          } else if (field.inputType == 'AGGREGATE_FUNCTION') {
            return this.getAggregateCurrencyFormat(templateData[field.key], field);
          } else if (
            field.input_type == "DATE_TIME_RANGE" ||
            (field.inputType == "DATE_TIME_RANGE" &&
              field.input_type == "ENTITY_VARIABLE")
          ) {
            return (
              this.$moment(value[0]).format("MM-DD-YYYY hh:mm:ss A") +
              " To " +
              this.$moment(value[1]).format("MM-DD-YYYY hh:mm:ss A")
            );
          } else if (
            field.input_type == "DATE_RANGE" ||
            (field.inputType == "DATE_RANGE" &&
              field.input_type == "ENTITY_VARIABLE")
          ) {
            return (
              this.$moment(value[0]).format("MM-DD-YYYY") +
              " To " +
              this.$moment(value[1]).format("MM-DD-YYYY")
            );
          } else if (
            (field.input_type == "TIME_RANGE" ||
              (field.inputType == "TIME_RANGE" &&
                field.input_type == "ENTITY_VARIABLE")) &&
            value
          ) {
            if (value.length) {
              return (
                this.$moment(value[0]).format("HH:mm:ss") +
                " - " +
                this.$moment(value[1]).format("HH:mm:ss")
              );
            }
          } else if (
            field.input_type == "QUESTION" &&
            field.question_type == "MCQ" &&
            field.has_other_option &&
            value[0] &&
            value[0].toLowerCase() == "others" &&
            otherValue
          ) {
            return otherValue;
          } else if (
            field.input_type == "FILE" ||
            (field.input_type == "ENTITY_VARIABLE" && field.inputType == "FILE")
          ) {
            this.documentsData = value.files;
            return value.files && value.files.length ? value.files : value;
          } else if (
            field.input_type == "DATE_TIME" ||
            (field.input_type == "ENTITY_VARIABLE" &&
              field.inputType == "DATE_TIME")
          ) {
            return moment(new Date(value)).format("MM-DD-YYYY hh:mm:ss A");
          } else if (field.input_type == "WEEKDAYS") {
            if (
              field?.allow_multiple &&
              typeof value == "object" &&
              value.length
            ) {
              let mappedArray = value.map((e) => this.weekDayMap[e]);
              return mappedArray.join(",");
            } else {
              return this.weekDayMap[value];
            }
          } else if (
            typeof value == "object" &&
            typeof value.length == "number"
          ) {
            return value.join(",");
          } else if (templateData[field.key + "/name"]) {
            return templateData[field.key + "/name"];
          }
          return value;
        } else if (field.input_type == "CURRENCY") {
          return 0;
        } else {
          return;
        }
      } else if (field.input_type == "CURRENCY") {
        return 0;
      }
      return;
    },
    getAggregateCurrencyFormat(value, field) {
      let resultValue = "";
      if (
        field.selected_fields?.[0]?.validations?.currency
      ) {
        let locale = field.validations?.locale || "en-US";
        return (resultValue = new Intl.NumberFormat(locale, {
          style: "currency",
          currency: field.selected_fields[0].validations.currency,
        }).format(value));
      } else {
        resultValue = value;
      }
      return resultValue;
    },
    async sortChange(a) {
      this.sortBy = a.prop;
      this.sortOrder = a.order == "ascending" ? "asc" : "desc";
      this.fetchAllWorkflowsTemplatesData();
      if (a.order == null) {
        this.sortBy = null;
        this.sortOrder = null;
        this.fetchAllWorkflowsTemplatesData();
      }
    },
    async loadData(data) {
      if (data?.type == "sort" && data.sort) {
        return;
      } else if (this.data && this.data.length) {
        this.pageSize = data.pageSize;
        this.page = data.page;
        await this.fetchAllWorkflowsTemplatesData();
      } else {
        await this.fetchAllWorkflowsTemplatesData();
      }
    },
    getFlowName() {
      return this.getFormBuilder?.name;
    },
    backToFormbuilder() {
      if (this.$route.name == "ApplicaionFormbuilderDataList") {
        this.$router.go(-1);
      } else {
        let UrlPathAdd = "";
        if (this.getIframeUrlInfo != null) {
          UrlPathAdd = `/if/${this.getIframeUrlInfo._id}/fbd/${this.formbuilder_code}`;
        } else {
          UrlPathAdd = "/fbd/" + this.formbuilder_code;
        }
        this.$router.push({ path: UrlPathAdd });
      }
    },
    async getNecessaryInfo() {
      this.loading = true;
      this.formbuilder_code = this.$route.params.formbuilder_code;

      await this.$store.dispatch("formBuilders/fetchFormBuilderByCode", {
        code: this.formbuilder_code,
        include_template_details: true,
        include_entity_details: true,
        include_documents_details: true,
      });

      this.formbuilderDetails = this.getFormBuilder;
      let entities = this.formbuilderDetails.steps.filter(
        (step) => step.type == "ENTITY"
      );
      let templates = this.formbuilderDetails.steps
        .filter(
          (step) => step.type == "TEMPLATE" && !step?.settings?.is_repeatable
        )
        .map((step) => step.template);
      entities.forEach((entity) => {
        let allowedTemplates = entity?.settings?.selectedTemplates
          ? entity.settings.selectedTemplates
          : [];
        if (entity?.entity?.templates) {
          entity.entity.templates.forEach((template) => {
            if (
              template?.template_id?._id &&
              !template.is_repeatable &&
              allowedTemplates.indexOf(template?.template_id?._id) != -1
            ) {
              let fields = this.getTemplateFields(
                template.template_id,
                "ENTITY",
                entity.entity
              );
              this.templateFields.push({
                fields: fields,
                label: entity.entity.name + "-" + template.template_id.name,
                key: template._id,
                entity_id: entity._id,
              });
              this.allFields = this.allFields.concat(fields);
            }
          });
        }
      });
      this.templateIds = templates.map((template) => template._id);
      this.templateFields = [
        ...this.templateFields,
        ...templates.map((template) => {
          template.fields = this.getTemplateFields(template, "TEMPLATE");
          this.allFields = this.allFields.concat(template.fields);
          template.label = template.name;
          template.key = template._id;
          return template;
        }),
      ];
      this.workflowIconPath =
        this.getFormBuilder.icon_type == "DEFAULT"
          ? this.getFormBuilder.icon || "block-portfolio.svg"
          : "block-portfolio.svg";
      this.templateFields = this.templateFields.map((template) => ({
        ...template,
        fields: template.fields.filter(
          (field) => this.contentFields.indexOf(field.input_type) === -1
        ),
      }));
      await this.fetchAllWorkflowsTemplatesData();
    },
    getTemplateFields(data, type, entity = {}) {
      let fields = [];
      data.sections.forEach((e) => {
        fields = [...fields, ...e.fields];
      });
      fields = fields.map((e) => {
        e.template_id = data._id;
        e.template_title = data.name;
        e.checked = true;
        e.table_display = true;
        e.type = type;
        if (type == "ENTITY" && entity?._id) {
          e.label = entity.name + "-" + e.label;
          e.data_key = entity._id + "#" + data._id + "#" + e.key;
          e.entity_type_id = entity._id;
        } else {
          e.data_key = data._id + "#" + e.key;
        }
        return e;
      });
      return fields;
    },
    async fetchAllWorkflowsTemplatesData() {
      this.loading = true;
      // this.data = [];
      let params = {
        page: this.currentPage,
        limit: this.pageSize > 0 ? this.pageSize : 10,
        sortBy: this.sortBy,
        sortOrder: this.sortOrder,
        formbuilder_details_id: this.getFormBuilder?._id,
        template_ids: this.templateIds,
      };

      this.filtersApplied = false;
      this.appliedFilters = [];

      await this.$store.dispatch(
        "formbuilderData/fetchUsersFormbuilderTemplatesData",
        params
      );
      this.loading = false;
      this.currentPage =
        parseInt(this.getUsersFormbuilderTemplatesData.page) || 1;
      this.total = this.getUsersFormbuilderTemplatesData.total || 0;

      if (
        this.getUsersFormbuilderTemplatesData.data &&
        this.getUsersFormbuilderTemplatesData.data.length
      ) {
        this.data = this.getUsersFormbuilderTemplatesData.data;
      }
      if (this.$route?.fullPath?.includes('FILLED_BY_ME')){
        this.data = this.data.filter(a=> this.getAuthenticatedUser?._id === a?.contact?._id);
      }
    },
    setFormbuilderData(formbuilderData) {
      return formbuilderData.map((row) => {
        if (row.formbuilder_steps_data && row.formbuilder_steps_data.length) {
          row.templates_data = row.formbuilder_steps_data
            .filter((e) => e.template_data_id)
            .map((e) => e.template_data_id);
        }
        return row;
      });
    },
    downloadDocumentsPopup() {
      this.downloadDocuments = true;
    },
    closeDownloadDocuments() {
      this.downloadDocuments = false;
    },
    async onPreview(data) {
      let fileName = data.name;
      fileName = await fileName.replace(/\//g, "-");
      fileName = await fileName.replace(/ /g, "_");
      fileName = await fileName.replace(/[()]/g, "");
      if (data && !data.path) {
        data.path = `template-data-documents/${fileName}`;
      }
      await this.$store.dispatch("templatesData/downloadTemplateDocument", {
        path: data.path,
      });
      let newWindow = open(
        this.getDownloadUrl,
        "example",
        "width=1500,height=900"
      );
      newWindow.focus();
      newWindow.onload = function () {};
    },
    async onDownloadFile(data) {
      let fileName = data.name;
      fileName = await fileName.replace(/\//g, "-");
      fileName = await fileName.replace(/ /g, "_");
      fileName = await fileName.replace(/[()]/g, "");
      if (data && !data.path) {
        data.path = `template-data-documents/${fileName}`;
      }
      await this.$store.dispatch("templatesData/downloadTemplateDocument", {
        path: data.path,
      });
      await fetch(this.getDownloadUrl, {
        method: "GET",
        responseType: "blob",
      })
        .then((res) => res.blob())
        .then((response) => {
          var fileURL = window.URL.createObjectURL(response);
          const link = document.createElement("a"); // Create an anchor tag
          link.href = fileURL; // Set the URL as the href attribute
          link.download = fileName; // Set the desired filename
          document.body.appendChild(link); // Append the anchor tag to the document body
          link.click(); // Simulate a click on the anchor tag to initiate the download
          document.body.removeChild(link);
        });
      if (this.getDownloadUrl) {
        // window.open(this.getDownloadUrl, "_blank");
        // this.$refs.this.getDownloadUrl.click();
      } else {
        this.$notify.error({
          title: "Error",
          message: this.getDownloadError,
        });
      }
    },
    openViewConfigPage() {
      (this.url_key = this.$route.fullPath), (this.copyModal = true);
    },
    async handleSizeChange(val) {
      this.loading = true;
      this.pageSize = val;
      this.currentPage = 1;
      await this.fetchAllWorkflowsTemplatesData();
      this.loading = false;
    },
    async handleCurrentChange(val) {
      this.loading = true;
      this.currentPage = val;
      await this.fetchAllWorkflowsTemplatesData();
      this.loading = false;
    },
  },
};
</script>
<style lang="scss">
.el-table .cell {
  word-break: break-word;
}
.el-table .td {
  word-wrap: break-word;
}
.el-table__header {
  word-wrap: break-word;
}
.Searching {
  width: 40% !important;
  float: right;
}
.custom-pagination {
  padding: 10px;
  margin-left: 27%;
}
</style>
<style scoped>
.table-scroll {
  max-height: 70vh; 
  overflow-x: auto;
  overflow-y: auto;
  scrollbar-width: thin; 
}
</style>
